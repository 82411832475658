<template>
<v-card class="rounded-lg" flat>
    <v-card-title class="pa-0 modal-title" v-if="vista == 'planilla'">
        <v-toolbar flat class="rounded-t-lg" height="56">
            <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;width:100%;">
                <v-list-item class="px-0 rounded-t-lg">
                    <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                    <v-list-item-content class="py-0">
                        <v-list-item-title class="headline pb-0 mb-0">
                            <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Inspectores de Linea</v-toolbar-title>
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium">
                            <span class="text-capitalize">Asignaciones</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="edit_btn">
                        <v-btn icon @click="menu_add_il = !menu_add_il" v-if="!menu_add_il">
                            <v-icon color="primary" size="20">mdi-account-plus-outline</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-toolbar-title>
        </v-toolbar>
    </v-card-title>
    <v-fade-transition mode="out-in" hide-on-leave>
        <v-card flat max-height="90vh" style="overflow: auto;" v-if="!menu_add_il">
            <v-card flat v-if="skeleton">
                <v-skeleton-loader type="list-item-avatar" v-for="(item, index) in 3" :key="index" />
            </v-card>
            <v-card flat v-if="ListaJornada.length">
                <v-list v-for="(jornada, index) in ListaJornada" :key="index" class="py-1">
                    <v-list-item class="pl-4">
                        <v-list-item-content class="py-0">
                            <v-list-item class="pa-0 list-planilla">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        <v-icon size="15" class="mr-2 mb-1">mdi-clock-outline</v-icon>{{jornada.jornada}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(il, index) in jornada.asignaciones" :key="index" class="rounded-lg list-planilla px-4">
                        <v-list-item-avatar class="my-0 mr-1 avatar-otros" height="48">
                            <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35">
                                <v-icon size="22" :color="TemaDark?'grey lighten-1':'grey darken-1'">mdi-account-circle-outline</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content class="py-0">
                            <v-list-item class="pa-0 list-planilla">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{il.nombre}}</v-list-item-title>
                                    <v-list-item-subtitle class="text-capitalize">{{il.inicio}} - {{il.termino}} | {{il.lugar}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="ma-0">
                                    <v-btn icon color="red" v-if="edit_btn" @click="EliminarIl(il.id)">
                                        <v-icon size="20">mdi-delete-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon color="primary" :href="Llamar(il.telefono)" v-else>
                                        <v-icon size="20">mdi-phone</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!jornada.asignaciones.length">
                        <v-row justify="center" align-content="center" class="text-center ma-0">
                            <v-col cols="12">
                                <v-card flat height="40" class="justify-center align-center d-flex flex-column">
                                    <v-icon size="30" color="grey lighten-1">
                                        mdi-information-outline
                                    </v-icon>
                                    <span class="trenos-title-toolbar grey--text text--lighten-1">Sin Informacion</span>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-card>
    </v-fade-transition>
    <v-fade-transition mode="out-in" hide-on-leave>
        <v-card class="text-center mb-3" flat v-if="menu_add_il">
            <v-card class="rounded-lg ma-3" flat v-if="menu_add_il" outlined >
                <v-toolbar flat class="rounded-t-lg" height="56">
                    <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;width:100%;">
                        <v-list-item class="px-0 rounded-t-lg">
                            <v-list-item-avatar class="mr-n1 rounded-lg"  >
                                <v-btn icon @click="menu_add_il = false" class="secondary" color="primary" small>
                                    <v-icon size="18">mdi-arrow-left</v-icon>
                                </v-btn>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0 text-left">
                                    <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Asignar Inspector de Linea</v-toolbar-title>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider class="pb-3 mx-4"></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-select v-model="il_nuevo.datos.il" :items="ListaNuevoIl" label="Seleccionar Inspector de linea" dense outlined item-text="nombre" item-value="id" hide-details :menu-props="{ rounded:'lg', transition:'slide-y-transition'}">
                                <template v-slot:item="{ item, on, attrs}">
                                    <v-list-item style="min-height:35px" class="pl-2" link v-bind="attrs" v-on="on">
                                        <v-list-item-avatar class="my-0 mr-1 avatar-otros" height="48">
                                            <v-avatar color="grey lighten-4" size="25">
                                                <v-icon color="primary" size="15">
                                                    mdi-account
                                                </v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-1">
                                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                                <span class="text-capitalize grey--text text--darken-3">{{item.nombre}}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:selection="{item}">
                                    <v-list-item style="min-height:30px" class="pl-0">
                                        <v-list-item-content class="py-1">
                                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                                <span class="text-capitalize grey--text text--darken-3">{{item.nombre}}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="ma-0">
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="il_nuevo.datos.tipo" :items="il_nuevo.lista_tipo" label="Jornada" dense outlined item-text="nombre" item-value="valor" hide-details :menu-props="{ rounded:'lg', transition:'slide-y-transition'}"/>
                        </v-col>
                        <v-col cols="6" >
                            <v-select v-model="il_nuevo.datos.lugar" :items="il_nuevo.lista_lugar" label="Lugar" dense outlined item-text="nombre" item-value="valor" hide-details :menu-props="{ rounded:'lg', transition:'slide-y-transition'}"/>
                        </v-col>
                        <v-col cols="6" >
                            <v-text-field label="Inicio" outlined dense v-model="il_nuevo.datos.inicio" autocomplete="off" type="time" hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Termino" outlined dense v-model="il_nuevo.datos.termino" :maxlength="9" autocomplete="off" type="time" hide-details />
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions class="pt-0">
                    <v-card flat class="text-center" width="100%">
                        <v-btn depressed @click="AsignarIl()" color="primary" width="130" :loading="edit_add_loading" :disabled="!AddIl">
                            Asignar
                        </v-btn>
                    </v-card>
                </v-card-actions>
            </v-card>
        </v-card>
    </v-fade-transition>
    
</v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dayjs from "dayjs";
export default {
    name: 'Ils',
    props: ['fecha', 'vista'],
    data() {
        return {
            lista: [],
            lista_edit: [],
            menu_add_il: false,
            il_nuevo: {
                lista_lugar: [
                    { nombre: 'Puerto', valor: 'pu' },
                    { nombre: 'Limache', valor: 'li'}, 
                    { nombre: 'El Belloto', valor: 'eb'}, 
                    { nombre: 'S. Aldea', valor: 'sa'}, 
                    { nombre: 'Teletrabajo', valor: 'tt'}
                ],
                lista_tipo: [
                    { nombre: 'Mañana', valor: 0 }, 
                    { nombre: 'Tarde', valor: 1}
                ],
                datos: {
                    il: null,
                    lugar: null,
                    inicio: null,
                    termino: null,
                    tipo: null,
                    fecha: null
                }
            },
            edit_add_loading: false,
            edit_del_loading: false,
            edit_btn: false,
            skeleton: false
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'update','sistema']),
        FormatFecha() {
            let fecha_nombre = `${dayjs(this.fecha).format(`dddd DD`)} de ${dayjs(this.fecha).format(`MMMM`)}`
            return fecha_nombre
        },
        AddIl() {
            let add_il = false
            if (this.il_nuevo.datos.il && this.il_nuevo.datos.tipo !== null && this.il_nuevo.datos.inicio !== null && this.il_nuevo.datos.termino !== null && this.il_nuevo.datos.lugar !== null) {
                add_il = true
            }
            return add_il
        },
        ListaJornada(){
            let lista_jornada = [
                { jornada: 'Mañana', asignaciones: [] },
                { jornada: 'Tarde', asignaciones: [] },
            ]
            if(this.lista){
                for (let index = 0; index < this.lista.length; index++) {
                    if(this.lista[index].jornada.includes('mañana')){
                        lista_jornada[0].asignaciones.push(this.lista[index])
                    }else{
                        lista_jornada[1].asignaciones.push(this.lista[index])
                    }
                }
            }
            return lista_jornada
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        ListaNuevoIl(){
            let lista_filtro = []
            lista_filtro = this.lista_edit
            for (let index = 0; index < this.lista_edit.length; index++) {
                let asignado = this.lista.findIndex(il => il.nombre === this.lista_edit[index].nombre)
                if(asignado > -1) lista_filtro.splice(index, 1)
            }
            return lista_filtro
        }
    },
    methods: {
        ...mapMutations(['ErrorRequest']),
        //Obtiene la lista de ils asignados para la fecha consultada.
        async GetData(fecha) {
            this.Overlay = true
            await this.axios.get(`/planilla/${fecha}/il`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.lista = respuesta.data.lista
                    if (respuesta.data.edit) this.edit_btn = true
                    this.skeleton = false
                }
            }).catch(err => {
                this.Overlay = false
                this.ErrorRequest(err)
            })
        },
        //Obtiene la lista completa de ils para asignar.
        async ListaIl(fecha) {
            this.Overlay = true
            await this.axios.get(`/planilla/edit/il/${fecha}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.lista_edit = respuesta.data.lista
                    this.il_nuevo.datos = {
                        il: null,
                        lugar: null,
                        inicio: null,
                        termino: null,
                        tipo: null,
                        fecha: null
                    }
                }
            }).catch(err => {
                this.Overlay = false
                this.ErrorRequest(err)
            })
        },
        //Envía los datos a la API para la asignacion de un il.
        async AsignarIl() {
            this.edit_add_loading = true
            this.il_nuevo.datos.fecha = this.fecha
            this.il_nuevo.datos.accion = 0 //agregar il
            await this.axios.post(`/planilla/edit/il`, this.il_nuevo.datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.edit_add_loading = false
                        this.menu_add_il = false
                        this.il_nuevo.datos = {
                            il: null,
                            lugar: null,
                            inicio: null,
                            termino: null,
                            tipo: null,
                            fecha: null
                        }
                        this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
                    }
                }
            }).catch(err => {
                this.Overlay = false
                this.ErrorRequest(err)
            })
        },
        async EliminarIl(id_usuario) {
            this.edit_del_loading = true
            let datos = {
                accion: 1,
                fecha: this.fecha,
                il: id_usuario
            }
            await this.axios.post(`/planilla/edit/il`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.edit_del_loading = false
                        this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
                    }
                }
            }).catch(err => {
                this.edit_del_loading = false
                this.ErrorRequest(err)
            })
        },
        //Genera el string con los datos del horario
        FormatHorario(inicio, termino) {
            let horario = ``
            if (inicio && termino) {
                horario = `${inicio} - ${termino}`
            }
            return horario
        },
        FormarTelefono(numero) {
            let telefono
            if (numero > 10000000) {
                telefono = `+569${numero}`
            }
            return telefono
        },
        Llamar(telefono){
            let href = ''
            if(telefono.toString().length == 8) href = `tel:+569${telefono}`
            return href
        }
    },
    mounted() {
        this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
    },
    watch: {
        menu_add_il: function (val) {
            if (val) this.ListaIl(dayjs(this.fecha).format('YYYYMMDD'))
        },
        fecha: function (val) {
            if (val) this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
        },
    }
}
</script>

<style>
.turno-opciones-btn {
    position: absolute;
    right: 1rem;
}

.v-slide-group__content{
    justify-content: center  !important;
}
</style>
